<template>
  <div class="booking-details py-8">
    <BookingAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <ViewingTitle :title="displayName" class="booking-details__title" />
        <DeleteOrRestore
          v-if="hasDeletePermission"
          :model="booking"
          :max-width="500"
          name="booking"
          redirect="/booking-requests"
        />

        <div class="d-flex mb-6">
          <v-tabs hide-slider class="booking-info-tabs">
            <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
              {{ item.tab }}
            </v-tab>

            <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
              <v-divider class="booking-details__divider"></v-divider>

              <BookingForm class="booking-details__form" :booking="booking" />
            </v-tab-item>
          </v-tabs>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Booking
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import BookingForm from '@/views/Home/Bookings/components/BookingForm'
import BookingAppbar from '@/views/Home/Bookings/components/BookingAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'BookingDetails',

  components: {
    ViewingTitle,
    BookingAppbar,
    BookingForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      booking: (state) => state.booking.bookingDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.booking ? this.booking.title : null
    },

    hasDeletePermission() {
      return validatePermissions([PERMISSION.BOOKINGS_DELETE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getBookingDetails: 'booking/getBookingDetails',
    }),

    ...mapMutations({
      clearBookingDetails: 'booking/clearBookingDetails',
    }),

    async getBooking() {
      this.loading = true
      await this.getBookingDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getBooking()
  },

  destroyed() {
    this.clearBookingDetails()
  },

  watch: {
    $route() {
      this.getBooking()
    },
  },
}
</script>

<style lang="scss" scoped>
.booking-details {
  &__title {
    padding-right: 10px;
  }

  &__divider {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
